import { Divider, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import {
  allState as allPlantsState,
  createSavePlantHandler,
  newPlant,
  validatePlant,
} from '../../../utilities/plants';
import { PlantForm } from 'components/Forms/PlantForms';
import PlantsList from 'components/widgets/PlantsList';

export const RoomsByLicenseDetailsView = props => {
  const { plantsState } = allPlantsState();

  const plantSaveHandler = createSavePlantHandler({
    plantsState,
  });

  return (
    <Fragment>
      {props.skippablePlantGroupSectionBuilder(
        <Fragment>
          <h2>
            Plant Groups in room '{props.room.name}' on License # {props.license}
          </h2>

          <Grid columns={2}>
            <Grid.Column width={11}>
              <PlantForm
                {...props}
                validator={validatePlant}
                submitText="+ Submit Plant Group"
                showCancel={false}
                onSubmit={plantSaveHandler}
                fieldValues={newPlant(props.room.id)}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <PlantsList
                title="Plant Groups Added To Room"
                plants={props.plants}
                onEdit={props.onEditPlant}
              />
            </Grid.Column>
          </Grid>
        </Fragment>
      )}
      <Divider />
      <br />
    </Fragment>
  );
};
