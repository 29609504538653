import { Button, Message } from 'semantic-ui-react';
import React, { Fragment, useState } from 'react';

import { RoomsByLicenseDetailsView } from './_RoomsByLicenseDetailsView';

export const PlantsByLicenseWizardView = props => {
  const { licenses } = props;
  const [itemIndex, setItemIndex] = useState(0);

  const previousLicense = () => setItemIndex(itemIndex - 1);
  const nextLicense = () => setItemIndex(itemIndex + 1);

  const actionsSection = () => (
    <>
      {itemIndex > 0 && (
        <Button onClick={previousLicense}>Back To Previous License</Button>
      )}
      {itemIndex < licenses.length - 1 && (
        <Button onClick={nextLicense}>Continue To Next License</Button>
      )}
      {!props.plantGroupsHaveBeenSkippedForAllRooms && (
        <Button onClick={props.nextInternalStep}>All Plants Are Setup</Button>
      )}
      {props.plantGroupsHaveBeenSkippedForAllRooms && (
        <Button onClick={props.nextStep}>Skip</Button>
      )}
    </>
  );

  const roomSection = props => (
    <RoomsByLicenseDetailsView
      {...props}
      skippablePlantGroupSectionBuilder={props.createSkippablePlantGroupSectionForRoom(
        props.room.id
      )}
      plants={props.plantsForRoom(props.room.id).map(plant => ({
        strain: (props.strainDetails[plant.strainId] || {}).name,
        ...plant,
      }))}
    />
  );

  const noPlantRoomsForLicenseSection = license =>
    props.allPlantRoomsForLicense(license).length === 0 ? (
      <Message header="You have no plants rooms for this license" />
    ) : null;

  const licenseSection = license => (
    <Fragment>
      {noPlantRoomsForLicenseSection(license)}

      {props.allPlantRoomsForLicense(license).map(room =>
        roomSection({
          room,
          license,
          ...props,
        })
      )}
    </Fragment>
  );

  return (
    <>
      <h3>Understanding Plant Groups in Traceability</h3>
      <p>
        Now that we understand your operation and where plants could be growing on your
        farm, it is time to get your plant information into GrowFlow.
      </p>

      <p>
        To simplify this, we will ask you some questions that help ‘group’ your plants.
        Each group of plants will live in the same room, should have the same Strain Name
        and the same birthday, along with some other details we will ask you.
      </p>

      <p>
        Keep adding plant groups to each room until all of the plants at your operation
        are accounted for.
      </p>

      {licenseSection(licenses[itemIndex])}
      <div>{actionsSection()}</div>
    </>
  );
};
