import { PageWithIntro, WizardLayout } from 'components';
import { MainPage } from './_MainPage';
import React from 'react';
import { plantsStep } from '../../../utilities/stepDefinitions';
import { useTracking } from '../../../utilities/hooks';

const PlantsPage = props => {
  useTracking('Plants');

  return (
    <PageWithIntro step={plantsStep}>
      <WizardLayout text>
        <MainPage {...props} />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default PlantsPage;
