import { Button, Divider, Grid, Message } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import NextStepButton from 'components/widgets/NextStepButton';
import PlantsList from 'components/widgets/PlantsList';

const RoomSection = ({ room, plants, onEditPlant, onAddPlant }) => (
  <Grid columns="2">
    <Grid.Row>
      <Grid.Column widthe={16}>
        <PlantsList title={`'${room.name}'`} plants={plants} onEdit={onEditPlant} />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column widthe={8}>
        <Button onClick={() => onAddPlant(room.id)}>+ Add Plant Group</Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const LicenseSection = ({
  license,
  rooms,
  plantsForRoom,
  onEditPlant,
  onAddPlant,
  strainDetails,
}) => (
  <Fragment>
    <h2>Plant Groups for License #{license}</h2>

    {rooms.length === 0 && <Message header="No plant rooms setup for this license" />}

    {rooms.map(room => (
      <Fragment>
        <RoomSection
          license={license}
          room={room}
          plants={plantsForRoom(room.id).map(plant => ({
            strain: strainDetails[plant.strainId].name,
            ...plant,
          }))}
          onEditPlant={onEditPlant}
          onAddPlant={onAddPlant}
          strainDetails={strainDetails}
        />
      </Fragment>
    ))}
  </Fragment>
);

export const PlantsListingView = ({
  licenses,
  allPlantRoomsForLicense,
  plantsForRoom,
  strainDetails,
  onEditPlant,
  onAddPlant,
  nextStep,
  stepCompleted,
}) => (
  <Fragment>
    {licenses.map(license => (
      <LicenseSection
        license={license}
        rooms={allPlantRoomsForLicense(license)}
        onEditPlant={onEditPlant}
        onAddPlant={onAddPlant}
        plantsForRoom={plantsForRoom}
        strainDetails={strainDetails}
      />
    ))}
    <Divider />

    <NextStepButton nextStep={nextStep} stepCompleted={stepCompleted} />
  </Fragment>
);
