import { ModalAddPlantForm, ModalEditPlantForm } from 'components/Forms/PlantForms';
import React, { useState } from 'react';
import {
  allState as allPlantsState,
  createQueriesHook as createPlantQueries,
  createSavePlantHandler,
  validatePlant,
} from '../../../utilities/plants';

import {
  allState as allRoomsState,
  createQueriesHook as createRoomQueries,
} from '../../../utilities/rooms';

import {
  allState as allSkipState,
  createSkippableSectionBuilderForKey,
  skipPlantGroupKeyBuilder,
} from '../../../utilities/skippedEntries';

import {
  allState as allStrainState,
  createQueriesHook as createStrainQueries,
} from '../../../utilities/strains';

import { InternalStepProgress } from 'components';
import { PlantsByLicenseWizardView } from './_PlantsByLicenseWizardView';
import { PlantsListingView } from './_PlantsListingView';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import YesNoModal from 'components/widgets/YesNoModal';
import { createQueriesHook as createAllQueries } from '../../../utilities/allQueries';
import { deleteFormElement } from '../../../utilities/formstate';
import { getLicenses } from '../../../utilities/licenses';
import { plantsStep } from '../../../utilities/stepDefinitions';
import { stepIsComplete } from '../../../utilities/steps';
import { validId } from '../../../utilities/matchers';

export const MainPage = props => {
  const [plantIdBeingEdited, setPlantIdBeingEdited] = useState('');
  const [showPlantDeleteModal, setShowPlantDeleteModal] = useState(false);
  const [showPlantAddModal, setShowPlantAddModal] = useState(false);
  const [roomToAddTo, setRoomToAddTo] = useState('');

  const { plantsState } = allPlantsState();
  const { plantRoomsState, inventoryRoomsState } = allRoomsState();
  const { strainState } = allStrainState();
  const { skipState } = allSkipState();
  const { licenses } = getLicenses();

  const stepCompleted = stepIsComplete(plantsStep);

  const allQueries = createAllQueries();

  const hideDeletionModal = () => {
    setShowPlantDeleteModal(false);
  };

  const hideAddModal = () => {
    setShowPlantAddModal(false);
  };

  const plantQueries = createPlantQueries({
    plantsState,
  });

  const roomQueries = createRoomQueries({
    inventoryRoomsState,
    plantRoomsState,
  });

  const strainQueries = createStrainQueries({
    strainState,
  });

  const onModalClose = () => {
    setPlantIdBeingEdited('');
    hideDeletionModal();
    hideAddModal();
    setRoomToAddTo('');
  };

  const plantSaveHandler = createSavePlantHandler({
    plantsState,
    completion: onModalClose,
  });

  const deletePlant = () => {
    deleteFormElement(plantsState, plantIdBeingEdited);
    onModalClose();
  };

  const promptForPlantDeletion = () => {
    setShowPlantDeleteModal(true);
  };

  const initiatePlantAdd = roomId => {
    setRoomToAddTo(roomId);
    setShowPlantAddModal(true);
  };

  const createDeletionModal = (deleteHandler, flag) => (
    <YesNoModal
      isOpen={flag}
      title="Delete Plant?"
      onYes={deleteHandler}
      onNo={hideDeletionModal}
    />
  );

  const createSkippablePlantGroupSectionForRoom = roomId =>
    createSkippableSectionBuilderForKey(
      {
        label: 'I have no plant groups for this room',
        keyBuilder: skipPlantGroupKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipPlantsForRoom(roomId),
      },
      roomId
    );

  const editPlant = id => {
    setPlantIdBeingEdited(id);
  };

  const plantEditModal = () =>
    validId(plantIdBeingEdited) ? (
      <ModalEditPlantForm
        strains={strainQueries.strains}
        stages={plantQueries.stages}
        plant={plantQueries.plantDetails[plantIdBeingEdited]}
        room={
          roomQueries.roomDetails[plantQueries.plantDetails[plantIdBeingEdited].roomId]
        }
        validator={validatePlant}
        saveHandler={plantSaveHandler}
        onClose={onModalClose}
        deleteHandler={promptForPlantDeletion}
      />
    ) : null;

  const plantAddModal = () =>
    showPlantAddModal ? (
      <ModalAddPlantForm
        stages={plantQueries.stages}
        strains={strainQueries.strains}
        room={roomQueries.roomDetails[roomToAddTo]}
        validator={validatePlant}
        saveHandler={plantSaveHandler}
        onClose={onModalClose}
      />
    ) : null;

  const plantDeletionModal = () => createDeletionModal(deletePlant, showPlantDeleteModal);

  return (
    <div>
      <h1>Add Plant Group Details</h1>
      <WistiaVideo id={plantsStep.intro.videoID} />

      {plantAddModal()}

      {plantEditModal()}

      {plantDeletionModal()}

      <InternalStepProgress
        nextStep={props.nextStep}
        stepsConfig={[
          {
            step: 1,
            label: 'Add Plant Details',
            component: (
              <PlantsByLicenseWizardView
                {...props}
                {...allQueries}
                {...plantQueries}
                {...roomQueries}
                {...strainQueries}
                createSkippablePlantGroupSectionForRoom={
                  createSkippablePlantGroupSectionForRoom
                }
                licenses={licenses}
                onEditPlant={editPlant}
              />
            ),
          },
          {
            step: 2,
            label: 'Confirm Plant Details',
            component: (
              <PlantsListingView
                stepCompleted={stepCompleted}
                {...props}
                {...allQueries}
                {...plantQueries}
                {...roomQueries}
                {...strainQueries}
                createSkippablePlantGroupSectionForRoom={
                  createSkippablePlantGroupSectionForRoom
                }
                onEditPlant={editPlant}
                onAddPlant={initiatePlantAdd}
                licenses={licenses}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
